import { useGeolocated } from "react-geolocated";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  setCoords,
  setGeolocation,
  setUserLocationInput,
} from "../appointmentSlice";
import { useEffect, useMemo, useRef } from "react";
import { setGeneralStep } from "../../helpType/helpTypeSlice";
import { GeneralStepsEnum } from "../../../common/constants/GeneralStepsEnum";
import { dossierTypeEnum } from "../../../types/dossierTypeEnum";
import { ApiGetAddressAutoComplete } from "../../../common/api/GeoServices/GetAddressByPostCode";
import AsyncSelect from "react-select/async";
import { ApiGetCoordsByAddress } from "../../../common/api/GeoServices/getCoordsByAddress";

interface LocationOption {
  label: string;
  value: string;
  description: string;
}

const AppointmentUserLocation = () => {
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated();
  const scrollRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      block: "start",
      inline: "nearest",
      behavior: "smooth",
    });
  }, []);

  const { dossier } = useAppSelector((state) => state.root);
  const { userLocation, agendaMoments } = useAppSelector(
    (state) => state.appointment,
  );

  const dispatch = useAppDispatch();

  const { helpType } = useAppSelector((state) => state);
  const { activeHelpType } = helpType;

  const processData = (locationOptions: LocationOption[]) => {
    const processedData: LocationOption[] = [];
    const duplicateLabels: string[] = [];

    for (const location of locationOptions) {
      const label = location.label;
      const value = location.value;
      const isDuplicate = processedData.some((item) => item.label === label);
      const descriptionPart = location.description.split(" ")[1];

      processedData.push(
        isDuplicate
          ? {
              ...location,
              label: `${label}, ${descriptionPart}`,
              value: `${value}${descriptionPart}`,
            }
          : location,
      );

      if (isDuplicate) {
        duplicateLabels.push(label);
      }
    }
    return processedData.filter(
      (item: { label: string; description: string }) =>
        !duplicateLabels.some((label) => label === item.label) &&
        !item.description.includes("cities"),
    );
  };

  const memoizedProcessData = useMemo(() => processData, []);

  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (userLocation.input) {
        const getCoords = await ApiGetCoordsByAddress(userLocation.input);
        const results = getCoords.data.status.message;

        if (results) {
          dispatch(setCoords({ lat: results.lat, lng: results.lng }));
        }
      }
    }, 600);
    return () => {
      clearTimeout(timeout);
    };
  }, [userLocation.input]);

  useEffect(() => {
    if (
      activeHelpType === dossierTypeEnum.GlassReplacement ||
      activeHelpType === dossierTypeEnum.GlassRepair
    ) {
      window.dataLayer.push({
        event: "RuitschadeAanmeldenVestiging",
      });
    }

    if (activeHelpType === dossierTypeEnum.BodyRepair) {
      window.dataLayer.push({
        event: "AutoschadeAanmeldenVestiging",
      });
    }

    if (activeHelpType === dossierTypeEnum.CaravanRepair) {
      window.dataLayer.push({
        event: "CamperschadeAanmeldenVestiging",
      });
    }
  }, []);

  const loadOptions = async (inputValue: string): Promise<LocationOption[]> => {
    if (inputValue.length > 1) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      return new Promise((resolve, reject) => {
        timeoutRef.current = setTimeout(() => {
          ApiGetAddressAutoComplete(inputValue)
            .then(
              (response: {
                data: { status: { message: { matches: LocationOption[] } } };
              }) => {
                const matches = response?.data?.status?.message?.matches || [];
                const data = memoizedProcessData(matches);
                resolve(
                  data?.map((item: LocationOption) => ({
                    value: item.value,
                    label: item.label,
                    description: item.description,
                  })),
                );
              },
            )
            .catch((error: unknown) => {
              console.error("Error fetching location autocomplete:", error);
              reject([]);
            });
        }, 500);
      });
    }
    return Promise.resolve([]);
  };

  if (dossier && !agendaMoments?.activeAgendaMoment) {
    return (
      <div
        className="s-appointment__group border-color-primary"
        ref={scrollRef}
      >
        <h1 className="s-appointment__group__title">Zoek een vestiging</h1>
        <div className="c-form__group">
          <div className="c-form__select react-select">
            {
              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={loadOptions}
                onChange={(option: LocationOption) =>
                  dispatch(setUserLocationInput(option.value))
                }
                noOptionsMessage={() =>
                  "Vul postcode of plaatsnaam in om te zoeken"
                }
                placeholder={"Vul uw postcode of plaatsnaam in"}
                loadingMessage={() => "Gegevens ophalen..."}
                menuPlacement="top"
              />
            }
          </div>
        </div>
        {coords && (
          <div className="c-form__group">
            <GeolocationButton
              coords={coords}
              isGeolocationAvailable={isGeolocationAvailable}
              isGeolocationEnabled={isGeolocationEnabled}
            />
          </div>
        )}
      </div>
    );
  } else return null;
};

const GeolocationButton = ({
  coords,
  isGeolocationAvailable,
  isGeolocationEnabled,
}: {
  coords: GeolocationCoordinates | null;
  isGeolocationAvailable: boolean;
  isGeolocationEnabled: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { userLocation } = useAppSelector((state) => state.appointment);
  let className = "btn btn--text";

  if (userLocation.geoLocationActive) {
    className += " isActive";
  }

  const handleClick = () => {
    dispatch(setGeneralStep(GeneralStepsEnum.Appointment));
    dispatch(setGeolocation(true));
    if (coords) {
      dispatch(setCoords({ lat: coords.latitude, lng: coords.longitude }));
    }
  };

  if (isGeolocationAvailable && isGeolocationEnabled && coords) {
    return (
      <button
        className={className}
        onClick={() => handleClick()}
        style={{ margin: 0 }}
      >
        <span className={"color-primary"}>Gebruik mijn huidige locatie</span>
      </button>
    );
  } else return null;
};

export default AppointmentUserLocation;
