export const GeneralStepsEnum = {
  Landing: 0,
  LicenseChecker: 1,
  TypeSelect: 2,
  BrandModel: 3,
  DamageCause: 4,
  GlassDamage: 5,
  CaravanDamage: 6,
  Lease: 7,
  Insurance: 8,
  Appointment: 9,
  Form: 10,
  EnableResume: 11,
  Finished: 12,
};
