export const SvgAanrijding = () => {
  return (
    <svg
      className="damage-collision"
      width="58px"
      height="38px"
      viewBox="0 0 58 38"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <polygon
          id="path-f52z351qdt-1"
          points="0 1.04083409e-16 57.106107 1.04083409e-16 57.106107 29.9618056 0 29.9618056"
        ></polygon>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="211118-ABS-platform-[ruitschade-2]"
          transform="translate(-668.000000, -1394.000000)"
        >
          <g id="aanrijding" transform="translate(668.000000, 1394.000000)">
            <g id="Fill-1-Clipped" transform="translate(0.000000, 7.492263)">
              <mask id="mask-f52z351qdt-2" fill="white">
                <use href="#path-f52z351qdt-1"></use>
              </mask>
              <g id="path-1"></g>
              <path
                className={"fill-primary"}
                d="M22.9917584,24.7272185 C22.9917584,23.2341342 21.7880501,22.039999 20.3040926,22.039999 C18.7459068,22.039999 17.5420914,23.2436897 17.5420914,24.7272185 C17.5420914,26.2203025 18.8114549,27.4892017 20.3040926,27.4892017 C21.7971588,27.4892017 22.9917584,26.2107292 22.9917584,24.7272185 L22.9917584,24.7272185 Z M16.1333445,11.5143603 L12.960909,6.21457863 C12.3262897,5.15071799 11.402759,4.37625101 10.0586135,4.37625101 L3.26585483,4.37625101 L3.26585483,11.5143603 L16.1333445,11.5143603 Z M32.8732047,16.8797079 L32.8732047,23.1033954 C32.8732047,24.6620275 31.8093441,25.2310809 30.5404449,25.2310809 L25.2401988,25.2310809 C25.1003511,27.8436612 22.9074211,29.9618092 20.2202017,29.9618092 C17.5330003,29.9618092 15.3497686,27.8345701 15.1255836,25.2310809 L-8.93024969e-06,25.2310809 L-8.93024969e-06,1.40874689 L11.5239425,1.40874689 C12.9326894,1.40874689 14.3509916,2.24888692 15.1906851,3.74150672 L20.1364717,11.4395963 L29.8128616,13.6325084 C32.3041335,14.0617319 32.7984406,15.6108087 32.8732047,16.8797079 L32.8732047,16.8797079 Z M46.6551341,19.2780158 C46.6551341,17.7849315 45.4514256,16.5161574 43.8931508,16.5161574 C42.3349829,16.5161574 41.1312746,17.7849315 41.1312746,19.2780158 C41.1312746,20.7706356 42.3349829,22.039999 43.8931508,22.039999 C45.3862349,22.039999 46.6551341,20.7706356 46.6551341,19.2780158 L46.6551341,19.2780158 Z M57.106116,13.6325084 L57.106116,25.5018103 L44.2571834,25.5018103 L44.2571834,28.3384327 C44.2571834,28.8327577 43.9022417,29.1781263 43.4170255,29.1781263 L38.8918358,29.1781263 C38.3970464,29.1781263 38.051678,28.823649 38.051678,28.3384327 L38.051678,17.0291287 C38.051678,15.9652681 38.1920079,15.1255924 38.7515237,13.9965411 L44.2571834,2.47260753 C44.8914455,1.12905147 46.6551341,0 48.1482003,0 L57.0499983,0 L57.0499983,3.66720703 L49.8462338,3.66720703 C48.2228573,3.66720703 47.5791291,4.09643056 46.813646,5.64504301 L43.0721392,13.6325084 L57.106116,13.6325084 Z"
                id="Fill-1"
                fill="#E67A01"
                fillRule="nonzero"
                mask="url(#mask-f52z351qdt-2)"
              ></path>
            </g>
            <polygon
              className={"fill-secondary"}
              id="Fill-4"
              fill="#E67A01"
              fillRule="nonzero"
              points="20.25 0 22.3382072 13.6885419 23.5949123 13.5160468 22.326668 5.20285733 26.9115878 11.8384464 29.9476568 9.72649916 29.0133023 14.7119346 34.7637248 12.3908614 33.5834764 16.1567262 34.8005484 16.5 36.75 10.2801035 30.6739414 12.7326899 31.7526387 6.97728205 27.2399538 10.1164161"
            ></polygon>
          </g>
        </g>
      </g>
    </svg>
  );
};
