import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PersonInterface } from "../../common/interfaces/PersonInterface";
import { ApiGetAddressByPostCode } from "../../common/api/GeoServices/GetAddressByPostCode";

export const getAddressByZipCode = createAsyncThunk(
  "form/getAddressByZipCode",
  async (_, { getState }) => {
    const { form } = getState() as { form: { person: PersonInterface } };

    if (form.person.address?.zip_code && form.person.address?.house_number) {
      const response = await ApiGetAddressByPostCode(
        form.person.address.zip_code,
        form.person.address.house_number,
      );
      return response.data.status.message;
    }
  },
);

export interface AppState {
  person: PersonInterface;
  zipcodeLoading: boolean;
  dateError: boolean;
  dateErrorText: string;
}

const initialState: AppState = {
  person: {
    first_name: "",
    last_name: "",
    insertion: "",
    email: "",
    mobile_number: "",
    mobile_number_input: "",
    address: {
      zip_code: "",
      house_number_addition: "",
      street: "",
      city: "",
      country: "NL",
    },
  },
  zipcodeLoading: false,
  dateError: false,
  dateErrorText: "",
};

const removeLeadingZero = (phoneNumber: string): string => {
  if (phoneNumber.startsWith("+310") || phoneNumber.startsWith("+320")) {
    return phoneNumber.replace("+310", "+31").replace("+320", "+32");
  }
  return phoneNumber;
};

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setFirstName: (state, action: PayloadAction<string>) => {
      state.person.first_name = action.payload;
    },
    setLastName: (state, action: PayloadAction<string>) => {
      state.person.last_name = action.payload;
    },
    setInsertion: (state, action: PayloadAction<string>) => {
      state.person.insertion = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.person.email = action.payload;
    },
    setMobile: (state, action: PayloadAction<string>) => {
      const modifiedPhoneNumber = removeLeadingZero(action.payload);
      state.person.mobile_number = modifiedPhoneNumber;
      state.person.mobile_number_input = modifiedPhoneNumber;
    },
    setAddressZipcode: (state, action: PayloadAction<string>) => {
      state.person.address.zip_code = action.payload;
    },
    setAddressHouseNumber: (state, action: PayloadAction<number>) => {
      state.person.address.house_number = action.payload;
    },
    setAddressHouseNumberAddition: (state, action: PayloadAction<string>) => {
      state.person.address.house_number_addition = action.payload;
    },
    setAddressStreet: (state, action: PayloadAction<string>) => {
      state.person.address.street = action.payload;
    },
    setAddressCity: (state, action: PayloadAction<string>) => {
      state.person.address.city = action.payload;
    },
    setAddressCountry: (state, action: PayloadAction<string>) => {
      state.person.address.country = action.payload;
    },
    setDateError: (state, action: PayloadAction<boolean>) => {
      state.dateError = action.payload;
    },
    setDateErrorText: (state, action: PayloadAction<string>) => {
      state.dateErrorText = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAddressByZipCode.pending, (state) => {
      state.zipcodeLoading = true;
    });
    builder.addCase(getAddressByZipCode.fulfilled, (state, action) => {
      state.person.address.street = action.payload.street;
      state.person.address.city = action.payload.city;
      state.zipcodeLoading = false;
    });
    builder.addCase(getAddressByZipCode.rejected, (state) => {
      state.zipcodeLoading = false;
    });
  },
});

export const {
  setFirstName,
  setLastName,
  setAddressCity,
  setAddressCountry,
  setAddressHouseNumber,
  setAddressHouseNumberAddition,
  setAddressStreet,
  setAddressZipcode,
  setEmail,
  setInsertion,
  setMobile,
  setDateError,
  setDateErrorText,
} = formSlice.actions;

export const formReducer = formSlice.reducer;
