export const SvgInbraak = () => {
  return (
    <svg
      className="damage-burglary"
      width="65px"
      height="43px"
      viewBox="0 0 65 43"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="211118-ABS-platform-[ruitschade-2]"
          transform="translate(-901.000000, -1393.000000)"
        >
          <g id="Group-5" transform="translate(901.000000, 1393.000000)">
            <g
              id="Group-3"
              transform="translate(33.000000, 0.000000)"
              fill="#E67A01"
              className="fill-primary"
            >
              <path
                d="M27.3059478,19.3967352 C27.1005858,22.5197712 23.9927238,24.7505112 17.2196658,20.5440192 C16.2906498,19.9669212 24.6963978,17.2934892 24.6963978,17.2934892 C24.6963978,17.2934892 27.5110938,16.2736992 27.3059478,19.3967352 M15.5414538,35.9201412 C12.9064698,35.9201412 10.7703918,34.7922972 10.7703918,33.4009872 C10.7703918,32.0097312 12.9064698,30.8819412 15.5414538,30.8819412 C18.1764378,30.8819412 20.3125158,32.0097312 20.3125158,33.4009872 C20.3125158,34.7922972 18.1764378,35.9201412 15.5414538,35.9201412 M3.7769598,19.6041492 C3.5718138,16.4811132 6.3864558,17.5009032 6.3864558,17.5009032 C6.3864558,17.5009032 14.7922578,20.1743352 13.8631878,20.7514332 C7.0901838,24.9579252 3.9823218,22.7271852 3.7769598,19.6041492 M28.6959618,7.2006732 C28.6517358,7.1101152 28.6066458,7.0199352 28.5606918,6.9301872 C26.8616358,3.6131832 23.9116158,1.4482692 20.3185638,0.5474412 C20.1424698,0.5032692 19.9655118,0.4621752 19.7880678,0.4239972 C18.3941118,0.1244052 16.9659198,4.31999997e-05 15.5414538,4.31999997e-05 C14.1169338,4.31999997e-05 12.6886878,0.1244052 11.2948398,0.4239972 C11.1172878,0.4621752 10.9404378,0.5032692 10.7643438,0.5474412 C7.1713458,1.4482692 4.2212178,3.6131832 2.5222158,6.9301872 C2.4762618,7.0199352 2.4311178,7.1101152 2.3868918,7.2006732 C0.6012198,10.8581472 -0.0397602,15.1123752 0.0018738,19.1489832 C0.0405378,22.8920472 0.7039818,26.6136192 1.8264798,30.1799412 C6.1678638,43.9745112 15.5414538,42.6658212 15.5414538,42.6658212 C15.5414538,42.6658212 24.9150438,43.9745112 29.2564818,30.1799412 C30.3788718,26.6136192 31.0423698,22.8920472 31.0809798,19.1489832 C31.1226678,15.1123752 30.4816338,10.8581472 28.6959618,7.2006732"
                id="Fill-1"
              ></path>
            </g>
            <g
              id="Group-4"
              transform="translate(0.000000, 13.000000)"
              fill="#E67A01"
              className="fill-secondary"
            >
              <path
                d="M22.8784904,15.6832705 C21.8163116,15.6832705 20.9136287,14.7802572 20.9136287,13.7184215 C20.9136287,12.6562554 21.7699355,11.7536614 22.8784904,11.7536614 C23.9869436,11.7536614 24.8432504,12.6562554 24.8432504,13.7184215 C24.8432504,14.7802572 23.9869436,15.6832705 22.8784904,15.6832705 L22.8784904,15.6832705 Z M4.15556634,15.6832705 C3.04710038,15.6832705 2.19079356,14.7802572 2.19079356,13.7184215 C2.19079356,12.6562554 3.04710038,11.7536614 4.15556634,11.7536614 C5.26410853,11.7536614 6.12040264,12.6562554 6.12040264,13.7184215 C6.12040264,14.7802572 5.21771969,15.6832705 4.15556634,15.6832705 L4.15556634,15.6832705 Z M6.23316654,4.02005272 C6.77773677,2.91839707 7.23567891,2.61303878 8.390521,2.61303878 L13.5152241,2.61303878 L18.6435103,2.61303878 C19.7983651,2.61303878 20.2563072,2.91839707 20.8008648,4.02005272 L23.4625502,9.70226057 L13.5551457,9.70226057 L13.478911,9.70226057 L3.57150651,9.70226057 L6.23316654,4.02005272 Z M26.5361955,9.96123001 L22.6195082,1.76322462 C22.1683002,0.807418745 20.9136287,0.00422213452 19.8514753,0.00422213452 L13.5152241,0.00422213452 L7.1825687,0.00422213452 C6.12040264,0.00422213452 4.86574385,0.807418745 4.41452308,1.76322462 L0.497848488,9.96123001 C0.0998268628,10.7644266 -2.54115788e-06,11.361764 -2.54115788e-06,12.1185845 L-2.54115788e-06,20.1639171 C-2.54115788e-06,20.5090953 0.245689349,20.7612544 0.597677892,20.7612544 L3.81685535,20.7612544 C4.16203359,20.7612544 4.41452308,20.5155753 4.41452308,20.1639171 L4.41452308,18.1459706 L13.478911,18.1459706 L13.5551457,18.1459706 L22.6195082,18.1459706 L22.6195082,20.1639171 C22.6195082,20.5155753 22.8720104,20.7612544 23.2171887,20.7612544 L26.4363788,20.7612544 C26.7883547,20.7612544 27.0340465,20.5090953 27.0340465,20.1639171 L27.0340465,12.1185845 C27.0340465,11.361764 26.9342171,10.7644266 26.5361955,9.96123001 L26.5361955,9.96123001 Z"
                id="Fill-1"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
