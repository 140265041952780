import { useState } from "react";
import { howToItems } from "../../constants/howToItems";

interface Props {
  code: string;
}

export const HowToCloud = (props: Props) => {
  const [showModal, setShowModal] = useState(false);
  const howToItem = howToItems?.find(
    (howToItem) => howToItem.codeName === props.code,
  );

  if (howToItem) {
    return (
      <>
        <button
          type={"button"}
          onClick={() => setShowModal(true)}
          className="btn--info"
        >
          <img src="/images/info.svg" alt="" />
        </button>

        {showModal && (
          <div className="c-info-popup c-info-popup--active">
            <div className="c-info-popup__bg"></div>
            <div className="c-info-popup__content">
              <button
                className="c-info-popup__close-btn c-info-popup__close"
                onClick={() => setShowModal(false)}
                type="button"
              >
                <img src="/images/cross-black.svg" />
              </button>
              <h1>{howToItem.title}</h1>
              <div dangerouslySetInnerHTML={{ __html: howToItem.content }} />
            </div>
          </div>
        )}
      </>
    );
  } else return null;
};
