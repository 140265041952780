export const SvgSteenslag = () => {
  return (
    <svg
      className="damage-rocks"
      width="47px"
      height="47px"
      viewBox="0 0 47 47"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="211118-ABS-platform-[ruitschade-2]"
          transform="translate(-446.000000, -1392.000000)"
          fillRule="nonzero"
        >
          <g id="steenslag" transform="translate(446.000000, 1392.000000)">
            <g
              id="Group-4"
              transform="translate(23.626092, 23.311361) scale(-1, 1) rotate(18.000000) translate(-23.626092, -23.311361) translate(5.126092, 4.811361)"
            >
              <polygon
                id="Fill-1"
                fill="#E67A01"
                className="fill-primary"
                points="6.81827313 17.2161478 4.1826967 17.6110576 2.69200116 20.4319243 2.83992145 22.3546897 -5.82867088e-16 26.0227842 1.70883165 28.510699 1.33743677 30.5637499 4.63713456 34.1757595 8.4175177 36.1899316 11.6082957 34.6684455 15.9771718 34.9559835 17.2155501 32.4681513 18.9004328 31.5996642 20.447034 29.5734976 21.4500005 25.1647198 19.6343252 20.0409852 16.3595402 16.4298027 14.7969236 16.4915125 13.199755 17.1619658 10.008977 16.3899312"
              ></polygon>
              <polygon
                id="Fill-2"
                fill="#E67A01"
                className="fill-primary"
                points="11.9223633 -4.9960036e-16 10.6951255 0.344255299 9.6814599 0.508834564 9.10811188 1.68423582 9.16503715 2.48535328 8.07274092 4.0136719 8.72997522 5.05031509 8.587116 5.90571483 9.85619454 7.41076962 11.3102461 8.25000018 12.5374838 7.61601936 14.2177348 7.73588587 14.6940231 6.69924268 15.3420429 6.33733326 15.9368914 5.49315295 16.3227411 3.65621727 15.6243486 1.52130646 14.3648259 0.016664166 13.7638343 0.042320385 13.1495328 0.32173392"
              ></polygon>
              <polygon
                id="Fill-3"
                fill="#E67A01"
                className="fill-secondary"
                points="26.6755845 3.65849007 24.8508758 3.88887061 23.8189457 5.53438984 23.9212962 6.65602468 21.9551986 8.79578778 23.1382773 10.2469969 22.881108 11.444615 25.1655182 13.551601 27.7827551 14.7265575 29.9917578 13.8389886 33.0163115 14.0067946 33.8737368 12.5555071 35.0401323 12.0489523 36.1108505 10.867017 36.8051991 8.29519237 35.548298 5.30628331 33.2810715 3.19976778 32.1992591 3.23583825 31.0935899 3.62688921 28.8845871 3.17655721"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
