import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setDamageCause } from "../vehicleInformationSlice";
import { useEffect, useRef } from "react";
import FormSelectString from "../../../common/components/form/FormSelectString";
import DamageCauseInterface from "../../../common/interfaces/DamageCauseInterface";
import { setDossierLoading, updateDossier } from "../../../app/rootReducer";
import { useGetDamageCauseQuery } from "../../apiData/apiDataSlice";
import { setGeneralStep } from "../../helpType/helpTypeSlice";
import { GeneralStepsEnum } from "../../../common/constants/GeneralStepsEnum";
import { PatchStepsEnum } from "../../../common/constants/PatchStepsEnum";
import { unsetAppointment } from "../../appointment/appointmentSlice";
import { dossierTypeEnum } from "../../../types/dossierTypeEnum";
import { useParams } from "react-router-dom";

const VehicleInformationDamageCause = () => {
  const { dossier, registrationLink } = useAppSelector((state) => state.root);
  const { activeHelpType } = useAppSelector((state) => state.helpType);
  const { damageCause } = useAppSelector((state) => state.vehicleInformation);
  const { data: damageCauses, isLoading } = useGetDamageCauseQuery("");

  const dispatch = useAppDispatch();
  const isInitialMount = useRef(true);
  const scrollRef = useRef<HTMLDivElement>(null);
  const renderCondition = !!dossier && !!activeHelpType && !!damageCauses;

  const { slug } = useParams();

  useEffect(() => {
    if (isLoading) {
      dispatch(setDossierLoading(true));
    } else {
      dispatch(setDossierLoading(false));
    }
  }, [isLoading]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      block: "start",
      inline: "nearest",
      behavior: "smooth",
    });
  }, [damageCauses]);

  useEffect(() => {
    if (!isInitialMount.current && damageCause.selected != undefined) {
      if (slug !== "athlon") {
        dispatch(setGeneralStep(GeneralStepsEnum.DamageCause));
      }

      dispatch(unsetAppointment());

      dispatch(
        updateDossier({
          data: { damage_cause_id: damageCause.selected },
          method: PatchStepsEnum.PatchDamageCause,
        }),
      ).then(() => {
        if (
          !registrationLink.private_lease &&
          !registrationLink.business_lease
        ) {
          dispatch(setGeneralStep(GeneralStepsEnum.Insurance));
          return;
        }

        dispatch(setGeneralStep(GeneralStepsEnum.Lease));
      });
    } else {
      isInitialMount.current = false;
    }
  }, [damageCause]);

  useEffect(() => {
    window.dataLayer.push({
      event: "AutoschadeAanmeldenUwschade",
    });
  }, []);

  if (
    renderCondition &&
    typeof dossier?.appointment_type !== "string" &&
    dossier?.appointment_type?.code !== dossierTypeEnum.CaravanRepair
  ) {
    return (
      <div
        className="s-appointment__group border-color-primary"
        ref={scrollRef}
      >
        <div className="s-appointment__group__header">
          <h1 className="s-appointment__group__title">Oorzaak schade</h1>
        </div>
        <div className="c-form__select react-select">
          {damageCauses && (
            <FormSelectString
              value={damageCause.selected}
              onChange={(value) => {
                if (parseInt(value) >= 0) {
                  dispatch(setDamageCause(parseInt(value)));
                  dispatch(setGeneralStep(GeneralStepsEnum.Lease));
                }
              }}
              options={damageCauses.map((causeOption: DamageCauseInterface) => {
                return {
                  value: causeOption.id,
                  label: causeOption.label,
                };
              })}
            />
          )}
        </div>
      </div>
    );
  } else return null;
};

export default VehicleInformationDamageCause;
