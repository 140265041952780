export const SvgDamageIcon = () => {
  return (
    <svg
      width="55.25px"
      height="38.41px"
      viewBox="0 0 74 52"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <polygon
          id="path-b6fvfl_-bg-1"
          points="0 0.0104578554 73.6667322 0.0104578554 73.6667322 38.6611154 0 38.6611154"
        ></polygon>
        <polygon
          id="path-b6fvfl_-bg-3"
          points="0.0101744634 0.0227635186 23.9613359 0.0227635186 23.9613359 25.2726966 0.0101744634 25.2726966"
        ></polygon>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="211118-ABS-platform-[autoschade-1]"
          transform="translate(-453.000000, -409.000000)"
        >
          <g id="Group-7" transform="translate(453.000000, 409.000000)">
            <g id="Group-3" transform="translate(0.000000, 12.580070)">
              <mask id="mask-b6fvfl_-bg-2" fill="white">
                <use href="#path-b6fvfl_-bg-1"></use>
              </mask>
              <g id="Clip-2"></g>
              <path
                className="fill-primary"
                d="M29.6593211,31.9085065 C29.6593211,29.9824317 28.1065404,28.4420005 26.192216,28.4420005 C24.1821835,28.4420005 22.6292415,29.9947581 22.6292415,31.9085065 C22.6292415,33.8345813 24.2667403,35.471481 26.192216,35.471481 C28.1182908,35.471481 29.6593211,33.8222549 29.6593211,31.9085065 L29.6593211,31.9085065 Z M20.8119616,14.8639763 L16.719528,8.02724842 C15.9008707,6.65489394 14.7095185,5.65583352 12.9755743,5.65583352 L4.21295591,5.65583352 L4.21295591,14.8639763 L20.8119616,14.8639763 Z M42.4063386,21.785238 L42.4063386,29.813779 C42.4063386,31.8244105 41.0339841,32.558511 39.3971074,32.558511 L32.5597805,32.558511 C32.3793773,35.9287098 29.5505262,38.6611154 26.0840202,38.6611154 C22.6175142,38.6611154 19.8011508,35.9169824 19.5119758,32.558511 L0,32.558511 L0,1.82773775 L14.8658449,1.82773775 C16.6831248,1.82773775 18.5127541,2.91151624 19.5959336,4.83699197 L25.9759857,14.7675079 L38.458527,17.5963589 C41.6722382,18.1500792 42.3099162,20.1483613 42.4063386,21.785238 L42.4063386,21.785238 Z M60.1849923,24.879049 C60.1849923,22.9529973 58.6322115,21.3162588 56.6220408,21.3162588 C54.6120083,21.3162588 53.0592276,22.9529973 53.0592276,24.879049 C53.0592276,26.8045247 54.6120083,28.4420005 56.6220408,28.4420005 C58.5481156,28.4420005 60.1849923,26.8045247 60.1849923,24.879049 L60.1849923,24.879049 Z M73.6667322,17.5963589 L73.6667322,32.9077282 L57.091642,32.9077282 L57.091642,36.5669638 C57.091642,37.2046419 56.6337681,37.6501663 56.0078635,37.6501663 L50.1703804,37.6501663 C49.5321033,37.6501663 49.0865788,37.1928915 49.0865788,36.5669638 L49.0865788,21.9779906 C49.0865788,20.6056131 49.2675811,19.5224337 49.9893551,18.0659601 L57.091642,3.20011527 C57.9098615,1.46693137 60.1849923,0.0104578554 62.111044,0.0104578554 L73.5943636,0.0104578554 L73.5943636,4.74114557 L64.3015027,4.74114557 C62.2073512,4.74114557 61.3769666,5.29486586 60.3894722,7.29254894 L55.5629381,17.5963589 L73.6667322,17.5963589 Z"
                id="Fill-1"
                fill=""
                mask="url(#mask-b6fvfl_-bg-2)"
              ></path>
            </g>
            <g id="Group-6" transform="translate(25.689598, 0.000230)">
              <mask id="mask-b6fvfl_-bg-4" fill="white">
                <use href="#path-b6fvfl_-bg-3"></use>
              </mask>
              <g id="Clip-5"></g>
              <polygon
                className="fill-secondary"
                fill=""
                mask="url(#mask-b6fvfl_-bg-4)"
                points="21.1315633 25.2726966 19.3648562 24.7473616 21.0781105 18.9844819 12.7308803 22.5364203 14.0871759 14.9072086 9.68003885 18.1391214 3.02463572 7.98467972 4.86557769 20.7063762 3.04136276 20.9703455 0.0101744634 0.0227473906 10.1566903 15.5039215 16.7072615 10.7000818 15.1414401 19.507559 23.9613359 15.7543893"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
