import axios from "axios";
import { ApiEnum } from "../../constants/ApiEnum";

export const ApiGetAddressByPostCode = async (
  postcode: string,
  houseNumber: number,
  houseNumberAddition?: string,
) => {
  return await axios
    .get(ApiEnum.POSTCODE + "/get", {
      params: {
        zip_code: postcode,
        house_number: houseNumber,
        house_number_addition: houseNumberAddition,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
};

export const ApiGetAddressAutoComplete = async (input: string) => {
  return await axios
    .get(ApiEnum.POSTCODE_AUTO_COMPLETE + "/?address=" + input)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
};
