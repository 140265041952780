import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setLoanType } from "../appointmentSlice";
import { HowToCloud } from "../../../common/components/howTo/HowToCloud";
import { useEffect, useRef } from "react";
import { updateDossier } from "../../../app/rootReducer";
import { PatchStepsEnum } from "../../../common/constants/PatchStepsEnum";
import { setGeneralStep } from "../../helpType/helpTypeSlice";
import { GeneralStepsEnum } from "../../../common/constants/GeneralStepsEnum";
import { dossierTypeEnum } from "../../../types/dossierTypeEnum";

interface loanType {
  type: string;
  icon: string;
  text: string;
  howToCode?: string;
}

const loanTypes = [
  {
    type: "bike",
    icon: "biycle",
    text: "Ik wil een leenfiets",
    howToCode: "borrowBicycle",
  },
  {
    type: "pickup",
    icon: "bus",
    text: "Ik wil haal- en brengservice",
    howToCode: "pickup",
  },
  {
    type: "car",
    icon: "car-front",
    text: "Ik wil een leenauto",
    howToCode: "borrowCar",
  },
  {
    type: "none",
    icon: "",
    text: "Ik wil geen leenvoertuig",
  },
];

const AppointmentLoanVehicle = () => {
  const { dossier } = useAppSelector((state) => state.root);
  const { activeHelpType } = useAppSelector((state) => state.helpType);
  const { agendaMoments, selectedTime, selectedDate, loanType } =
    useAppSelector((state) => state.appointment);
  const { activeAgendaMoment } = agendaMoments;

  const dispatch = useAppDispatch();

  const initialMountLoanType = useRef(true);
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      block: "start",
      inline: "nearest",
      behavior: "smooth",
    });

    if (!initialMountLoanType.current) {
      if (loanType && loanType !== "") {
        dispatch(
          updateDossier({
            data: { loan_vehicle_code: loanType },
            method: PatchStepsEnum.PatchLoanVehicle,
          }),
        ).then(() => {
          dispatch(setGeneralStep(GeneralStepsEnum.Form));
        });
      }
    } else {
      initialMountLoanType.current = false;
    }
  }, [loanType]);

  if (
    dossier &&
    selectedDate &&
    activeAgendaMoment &&
    selectedTime?.startTime &&
    selectedTime?.endTime &&
    (activeHelpType === dossierTypeEnum.BodyRepair ||
      activeHelpType === dossierTypeEnum.CaravanRepair)
  ) {
    return (
      <div
        className="s-appointment__group border-color-primary"
        ref={scrollRef}
      >
        <div className="s-appointment__group__header">
          <h1 className="s-appointment__group__title s-appointment__group__title--no-margin">
            Heb je vervangend vervoer nodig?
          </h1>
          <HowToCloud code={"borrowVehicles"} />
        </div>
        <p>
          Onderstaande opties bij beschikbaarheid. De vestiging neemt hierover
          contact op.
        </p>
        <div className="c-form__group">
          <div className="c-form__buttons c-form__buttons--start">
            {loanTypes.map((loanType) => {
              return <LoanVehicle key={loanType.type} {...loanType} />;
            })}
          </div>
        </div>
      </div>
    );
  } else return null;
};

const LoanVehicle = (props: loanType) => {
  const { loanType } = useAppSelector((state) => state.appointment);

  const dispatch = useAppDispatch();

  let className =
    "c-form__buttons__single border-color-primary border-color-primary-hover";

  if (loanType === props.type) {
    className += " button-active";
  }

  const handleClick = (props: { type: string }) => {
    dispatch(setGeneralStep(GeneralStepsEnum.Appointment));
    dispatch(setLoanType(props.type));
  };

  return (
    <button className={className} onClick={() => handleClick(props)}>
      <span className="font-face-primary">{props.text}</span>
    </button>
  );
};

export default AppointmentLoanVehicle;
