import { ApiEnum } from "../../constants/ApiEnum";
import axios from "axios";
import { disableApiError } from "../../../app/rootReducer";
import { Dispatch } from "redux";

export const ApiCaravanSendExplanationEmail = async (
  dossierUuid: number,
  dossierAccessToken: string,
  dossierId: number | undefined,
  message: string,
  establishmentId: number,
  dispatch: Dispatch,
) => {
  return await axios
    .post(
      ApiEnum.CARAVAN_SEND_MAIL,
      {
        dossierId: dossierId,
        dossierUuid: dossierUuid,
        dossierAccessToken: dossierAccessToken,
        establishmentId: establishmentId,
        message: message,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    )
    .then(function (response) {
      dispatch(disableApiError());
      return response;
    })
    .catch(function (error) {
      return error;
    });
};
