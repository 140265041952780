import DossierInterface from "../interfaces/DossierInterface";
import { PatchStepsEnum } from "../constants/PatchStepsEnum";

export const formatCreateAndUpdateData = (dossier: DossierInterface) => {
  const PatchEmail = {
    data: { email: dossier.person?.email },
    method: PatchStepsEnum.PatchEmail,
  };
  const patchCarLicensePlate = {
    data: { car_license_plate: dossier.car_license_plate },
    method: PatchStepsEnum.patchCarLicensePlate,
  };
  const patchModel = {
    data: { car_brand: dossier.car_brand, car_model: dossier.car_model },
    method: PatchStepsEnum.PatchModel,
  };
  const patchYear = {
    data: { car_build_year: dossier.car_build_year },
    method: PatchStepsEnum.PatchYear,
  };
  const patchAppointmentType = {
    data: {
      appointment_type:
        typeof dossier.appointment_type === "object"
          ? dossier.appointment_type?.code
          : dossier.appointment_type,
    },
    method: PatchStepsEnum.PatchAppointmentType,
  };
  const patchDamageCause = {
    data: { damage_cause_id: dossier.damage_cause?.id },
    method: PatchStepsEnum.PatchDamageCause,
  };
  const patchGlassDamageLocation = {
    data: { glass_damage_location: dossier.glass_damage_location },
    method: PatchStepsEnum.PatchGlassDamageLocation,
  };
  const patchGlassDamageSize = {
    data: { glass_damage_size: dossier.glass_damage_size },
    method: PatchStepsEnum.PatchGlassDamageSize,
  };
  const patchGlassDamageExactLocation = {
    data: { glass_damage_exact_location: dossier.glass_damage_exact_location },
    method: PatchStepsEnum.PatchGlassDamageExactLocation,
  };
  const patchGlassDamageExactSize = {
    data: { glass_damage_exact_size: dossier.glass_damage_exact_size },
    method: PatchStepsEnum.PatchGlassDamageExactSize,
  };
  const patchGlassDamageCause = {
    data: { glass_damage_cause: dossier.glass_damage_cause },
    method: PatchStepsEnum.PatchGlassDamageCause,
  };
  const patchLeaseCar = {
    data: { lease_car: dossier.lease_car },
    method: PatchStepsEnum.PatchLeaseCar,
  };
  const patchContractor = {
    data: { contractor_id: dossier.contractor_id },
    method: PatchStepsEnum.PatchContractor,
  };
  const patchLeaseTypeCode = {
    data: { lease_type_code: dossier.lease_type_code },
    method: PatchStepsEnum.PatchLeaseTypeCode,
  };
  const patchAppointment = {
    data: {
      establishment_id: dossier.establishment_id,
      date: dossier.date,
      start_time: dossier.start_time,
      end_time: dossier.end_time,
    },
    method: PatchStepsEnum.PatchAppointment,
  };
  const patchLoanVehicle = {
    data: { loan_vehicle_code: dossier.loan_vehicle_code },
    method: PatchStepsEnum.PatchLoanVehicle,
  };
  const patchDamageDate = {
    data: { damage_date: dossier.damage_date },
    method: PatchStepsEnum.PatchDamageDate,
  };
  const patchPerson = {
    data: {
      person: {
        first_name: dossier.person?.first_name,
        insertion: dossier.person?.insertion,
        last_name: dossier.person?.last_name,
        email: dossier.person?.email,
        mobile_number: dossier.person?.mobile_number,
        address: {
          zip_code: dossier.person?.address?.zip_code,
          house_number: dossier.person?.address?.house_number,
          house_number_addition: dossier.person?.address?.house_number_addition,
          street: dossier.person?.address?.street,
          city: dossier.person?.address?.city,
          country: dossier.person?.address?.country,
        },
      },
    },
    method: PatchStepsEnum.PatchPerson,
  };
  const patchGreenCardCode = {
    data: {
      green_card_code: dossier.green_card_code,
    },
    method: PatchStepsEnum.PatchGreenCardCode,
  };

  const returnArray = [];

  if (dossier.person?.email) returnArray.push(PatchEmail);
  if (dossier.car_license_plate) returnArray.push(patchCarLicensePlate);
  if (!dossier.car_license_plate && !!dossier.car_brand && !!dossier.car_model)
    returnArray.push(patchModel);
  if (dossier.car_build_year) returnArray.push(patchYear);
  if (dossier.appointment_type) returnArray.push(patchAppointmentType);
  if (dossier.damage_cause?.id) returnArray.push(patchDamageCause);
  if (dossier.glass_damage_location) returnArray.push(patchGlassDamageLocation);
  if (dossier.glass_damage_size) returnArray.push(patchGlassDamageSize);
  if (dossier.glass_damage_exact_location)
    returnArray.push(patchGlassDamageExactLocation);
  if (dossier.glass_damage_exact_size)
    returnArray.push(patchGlassDamageExactSize);
  if (dossier.glass_damage_cause) returnArray.push(patchGlassDamageCause);
  if (typeof dossier.lease_car !== "undefined" && dossier.lease_car !== null)
    returnArray.push(patchLeaseCar);
  if (dossier.contractor_id) returnArray.push(patchContractor);
  if (dossier.lease_type_code) returnArray.push(patchLeaseTypeCode);
  if (
    !!dossier.establishment_id &&
    !!dossier.date &&
    !!dossier.start_time &&
    !!dossier.end_time
  )
    returnArray.push(patchAppointment);
  if (dossier.loan_vehicle_code) returnArray.push(patchLoanVehicle);
  if (dossier.damage_date) returnArray.push(patchDamageDate);
  if (
    !!dossier.person?.first_name &&
    !!dossier.person?.email &&
    !!dossier.person?.mobile_number &&
    !!dossier.person?.address
  )
    returnArray.push(patchPerson);
  if (dossier.green_card_code) returnArray.push(patchGreenCardCode);

  return { data: returnArray };
};
