import { useAppSelector } from "../../../app/hooks";

export const Header = () => {
  const { root } = useAppSelector((state) => state);
  const { registrationLink } = root;
  return (
    <>
      <header className="c-header c-header--appointment">
        <a href={"https://www.absautoherstel.nl"} className="c-header__back">
          <i className="icon-arrow-left color-primary-before" />
          <span className=" font-face-primary-bold">terug</span>
        </a>

        <img
          src={process.env.REACT_APP_BASE_URL + "/" + registrationLink.logo_url}
          className="c-header__logo"
        />

        <a href={"tel:0900-6611111"} className="c-switch__header__help">
          <i className="icomoon-phone icomoon color-primary-before" />
          <span className="font-face-primary-bold">0900 - 66 11 11 1</span>
        </a>
      </header>
    </>
  );
};
