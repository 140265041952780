import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { enableSmartPhotoModal } from "../../../app/rootReducer";
import { dossierTypeEnum } from "../../../types/dossierTypeEnum";

const ImageCaptureInfo = () => {
  const dispatch = useAppDispatch();
  const { dossier } = useAppSelector((state) => state.root);

  if (
    dossier &&
    typeof dossier.appointment_type !== "string" &&
    dossier?.appointment_type?.code ===
      (dossierTypeEnum.BodyRepair || dossierTypeEnum.CaravanRepair)
  ) {
    return (
      <div className="s-appointment__group border-color-primary">
        <div className="s-appointment__group__header">
          <h1 className="s-appointment__group__title">
            Eenvoudig foto&apos;s maken van jouw schade
          </h1>
          <button
            className="btn--info"
            onClick={() => dispatch(enableSmartPhotoModal())}
          >
            <span>
              <u>Hoe werkt dit?</u>
            </span>
          </button>
        </div>

        {dossier && (
          <p>
            Na het afronden van dit formulier ontvang je via sms een link
            waarmee je toegang krijgt tot onze slimme software.
          </p>
        )}
        {dossier &&
          dossier.appointment &&
          dossier.appointment.establishment && (
            <p>
              Bevestig jouw foto’s en{" "}
              <u>{dossier.appointment.establishment.name}</u> neemt zo snel
              mogelijk contact met je op om jouw afspraak te bevestigen.
            </p>
          )}
      </div>
    );
  }

  return null;
};

export default ImageCaptureInfo;
