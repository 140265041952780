export const SvgStorm = () => {
  return (
    <svg
      className="damage-storm"
      width="51px"
      height="42px"
      viewBox="0 0 51 42"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <polygon
          id="path-nv8tg65nd0-1"
          points="0 0 2.69999993 0 2.69999993 5.39999985 0 5.39999985"
        ></polygon>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="211118-ABS-platform-[ruitschade-2]"
          transform="translate(-675.000000, -1528.000000)"
        >
          <g id="storm" transform="translate(675.000000, 1528.000000)">
            <path
              d="M22.4259342,17.9180535 C23.4150414,16.9034315 24.6767945,16.2448164 26.0478248,16.0137963 C26.0776773,14.1546277 26.8019024,12.3215573 28.1015634,10.8727428 C28.8468566,10.0419016 29.7332111,9.3852184 30.7359141,8.92102767 C31.3890252,8.61867495 32.0708954,8.40857715 32.7740522,8.29171846 C30.786689,6.73636059 28.169324,5.96996293 25.4256233,6.44246425 C24.1894215,2.64513992 20.6270904,0 16.53638,0 C10.8816731,0 6.67924344,4.99058793 7.23791256,10.1224196 C3.22823054,10.2316238 0,13.5275055 0,17.5627389 C0,21.6671181 3.33889272,25.0060108 7.44327186,25.0060108 L21.0334715,25.0060108 C20.7216782,24.239686 20.5494155,23.4022473 20.5494155,22.525224 C20.5494155,20.7955621 21.2158308,19.1593947 22.4259342,17.9180535"
              id="Fill-1"
              fill="#E67A01"
              className="fill-primary"
              fillRule="nonzero"
            ></path>
            <path
              d="M48.0696262,17.0911527 C46.6591207,15.9027369 44.8070963,15.4007111 42.9884965,15.7139988 L42.2113825,15.8478068 L41.9673133,15.097994 C41.5607502,13.8490348 40.7822875,12.7775506 39.716125,11.9994525 C38.7400307,11.2870737 37.6009317,10.8796721 36.401836,10.8106723 C36.2803482,10.8037103 36.1581679,10.8001018 36.0354772,10.8001018 C34.2434859,10.8001018 32.592374,11.5405835 31.3862799,12.8850417 C30.2176201,14.1878375 29.6504581,15.8814501 29.8301202,17.5317239 L29.9370645,18.5143063 L28.9490143,18.5412429 C27.7023151,18.5752143 26.5339104,19.0881387 25.6590375,19.9855742 C24.7826338,20.8845769 24.2999994,22.0689467 24.2999994,23.3205668 C24.2999994,24.228281 24.5542746,25.0776388 24.995283,25.8013536 C25.8352003,27.1795644 27.3525409,28.1017858 29.0812549,28.1017858 L44.0550964,28.1017858 C47.4955389,28.1017858 50.2944978,25.302827 50.2944978,21.862421 C50.2944978,20.0214774 49.4835582,18.2824114 48.0696262,17.0911527"
              id="Fill-3"
              fill="#E67A01"
              className="fill-primary"
              fillRule="nonzero"
            ></path>
            <path
              d="M9.44999974,35.1001011 L9.44999974,35.1001011 C8.70443925,35.1001011 8.09999979,34.5745006 8.09999979,33.9261861 L8.09999979,30.8740161 C8.09999979,30.2256571 8.70443925,29.7001012 9.44999974,29.7001012 C10.1956113,29.7001012 10.7999997,30.2256571 10.7999997,30.8740161 L10.7999997,33.9261861 C10.7999997,34.5745006 10.1956113,35.1001011 9.44999974,35.1001011"
              id="Fill-5"
              fill="#E67A01"
              className="fill-secondary"
              fillRule="nonzero"
            ></path>
            <g id="Fill-7-Clipped" transform="translate(14.850000, 35.100101)">
              <mask id="mask-nv8tg65nd0-2" fill="white">
                <use href="#path-nv8tg65nd0-1"></use>
              </mask>
              <g id="path-1"></g>
              <path
                d="M1.34999996,5.39999985 L1.34999996,5.39999985 C0.604388373,5.39999985 0,4.87439946 0,4.22608491 L0,1.17391494 C0,0.525555972 0.604388373,0 1.34999996,0 C2.09561156,0 2.69999993,0.525555972 2.69999993,1.17391494 L2.69999993,4.22608491 C2.69999993,4.87439946 2.09561156,5.39999985 1.34999996,5.39999985"
                id="Fill-7"
                fill="#E67A01"
                className="fill-secondary"
                fillRule="nonzero"
                mask="url(#mask-nv8tg65nd0-2)"
              ></path>
            </g>
            <path
              d="M22.9499994,36.4501011 L22.9499994,36.4501011 C22.2043878,36.4501011 21.5999994,35.9245451 21.5999994,35.2761861 L21.5999994,32.2240161 C21.5999994,31.5757015 22.2043878,31.0501013 22.9499994,31.0501013 C23.6955598,31.0501013 24.2999994,31.5757015 24.2999994,32.2240161 L24.2999994,35.2761861 C24.2999994,35.9245451 23.6955598,36.4501011 22.9499994,36.4501011"
              id="Fill-10"
              fill="#E67A01"
              className="fill-secondary"
              fillRule="nonzero"
            ></path>
            <path
              d="M36.4499735,36.4501011 L36.4499735,36.4501011 C35.7044271,36.4501011 35.0999991,35.9245451 35.0999991,35.2761861 L35.0999991,32.2240161 C35.0999991,31.5757015 35.7044271,31.0501013 36.4499735,31.0501013 C37.1955709,31.0501013 37.799999,31.5757015 37.799999,32.2240161 L37.799999,35.2761861 C37.799999,35.9245451 37.1955709,36.4501011 36.4499735,36.4501011"
              id="Fill-15"
              fill="#E67A01"
              className="fill-secondary"
              fillRule="nonzero"
            ></path>
            <path
              d="M29.6999736,40.500101 L29.6999736,40.500101 C28.9544272,40.500101 28.3499992,39.9745449 28.3499992,39.326186 L28.3499992,36.274016 C28.3499992,35.6257014 28.9544272,35.1001011 29.6999736,35.1001011 C30.4455711,35.1001011 31.0499992,35.6257014 31.0499992,36.274016 L31.0499992,39.326186 C31.0499992,39.9745449 30.4455711,40.500101 29.6999736,40.500101"
              id="Fill-15"
              fill="#E67A01"
              className="fill-secondary"
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
