import { useAppSelector } from "../../../app/hooks";
import { format } from "date-fns";
import { nl } from "date-fns/locale";
import { scrollToId } from "../../../common/helpers/scrollToId";
import { dossierTypeEnum } from "../../../types/dossierTypeEnum";

const OverviewAppointment = () => {
  const { helpType } = useAppSelector((state) => state);
  const { dossier } = useAppSelector((state) => state.root);
  const { agendaMoments } = useAppSelector((state) => state.appointment);
  const isCaravanDamage =
    helpType.activeHelpType === dossierTypeEnum.CaravanRepair;

  if (
    (dossier && dossier.appointment) ||
    (agendaMoments && agendaMoments.activeAgendaMoment)
  ) {
    return (
      <div className="s-appointment__group border-color-primary">
        <div className="s-appointment__group__header">
          <h1 className="s-appointment__group__title">Gekozen vestiging</h1>
          <button
            className="btn--info"
            onClick={() => {
              scrollToId("establishment");
            }}
          >
            <span>
              <u>Wijzig</u>
            </span>
          </button>
        </div>

        {agendaMoments?.activeAgendaMoment?.establishment && (
          <h1 className="s-appointment__group__title">
            {agendaMoments.activeAgendaMoment.establishment.name}
          </h1>
        )}

        {agendaMoments?.activeAgendaMoment?.establishment && (
          <p>
            {agendaMoments.activeAgendaMoment.establishment.address.street}{" "}
            {
              agendaMoments.activeAgendaMoment.establishment.address
                .house_number
            }
            , {agendaMoments.activeAgendaMoment.establishment.address.zip_code}{" "}
            {agendaMoments.activeAgendaMoment.establishment.address.city}
          </p>
        )}

        {dossier?.appointment?.date_start && !isCaravanDamage && (
          <p>
            Gekozen voorkeursdatum{" "}
            {format(
              new Date(dossier.appointment.date_start),
              "EEEE d MMMM yyyy",
              { locale: nl },
            )}
            , {dossier.appointment.start_time} - {dossier.appointment.end_time}
          </p>
        )}
      </div>
    );
  } else return null;
};

export default OverviewAppointment;
