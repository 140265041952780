// Need to use the React-specific entry point to allow generating React hooks
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AgendaMomentInterface } from "../../common/interfaces/AgendaMomentInterface";

const urlBase = window.location.origin;
const aanmeldlink = window.location.pathname.replace(/\//, "");

export type getAgendaMomentsParams = {
  dossierId: number;
  dossierAccessToken: string;
  long?: number;
  lat?: number;
  include_distances?: boolean;
  limit?: number;
  sort?: string;
  preferredEstablishmentId?: number;
  showOnlyPreferredEstablishment?: boolean;
  fundingType?: string;
};

interface ApiResponse {
  status: {
    code: string;
    message: {
      count: number;
      data: AgendaMomentInterface[];
    };
  };
}

// Define a service using a base URL and expected endpoints
export const apiDataSlice = createApi({
  reducerPath: "apiData",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  endpoints: (builder) => ({
    getContractorsByType: builder.query<
      object[],
      { dossierId: number; type: string }
    >({
      query: (params) => {
        if (!params.dossierId) {
          throw new Error("dossierId is required");
        }
        return {
          url: "/api/v1/contractor",
          params: {
            code: params.type,
            dossierId: params.dossierId,
            urlBase,
            aanmeldlink,
          },
        };
      },
    }),
    getDamageCause: builder.query<object[], string>({
      query: () => "/api/v1/damage-cause",
    }),
    getAgendaMoments: builder.query<
      { count: number; data: AgendaMomentInterface[] },
      getAgendaMomentsParams
    >({
      query: (params: getAgendaMomentsParams) => {
        if (!params.dossierId) {
          throw new Error("dossierId is required");
        }
        return {
          url: "/api/v1/dossier/possible-agenda-moments-by-lat-long",
          params,
        };
      },
      transformResponse: (response: ApiResponse) =>
        response.status.code === "success"
          ? response.status.message
          : { count: 0, data: [] },
    }),
  }),
});

export const {
  useGetContractorsByTypeQuery,
  useLazyGetContractorsByTypeQuery,
  useGetDamageCauseQuery,
  useLazyGetAgendaMomentsQuery,
} = apiDataSlice;
