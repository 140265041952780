import { useState, useEffect } from "react";
import Select from "react-select/async";
import debounce from "debounce-promise";
import Fuse, { IFuseOptions } from "fuse.js";
import { CSSObject } from "@emotion/react";

interface Option {
  label: string;
  value: number | string;
}

interface Props {
  options: Option[];
  fuzzyOptions: IFuseOptions<Option>;
  wait: number;
  value: number | string;
  onChange: (value: Option | null) => void;
}

const FuzzyReactSelect = (props: Props) => {
  const [fuse, setFuse] = useState<Fuse<Option> | null>(null);

  const activeOption = props.options.find((option: Option) => {
    return option.value === props.value;
  }) as Option;

  const searchOptions = (inputValue: string) =>
    new Promise((resolve, reject) => {
      if (fuse) {
        resolve(
          fuse.search(inputValue).map((c: { item: object }) => ({ ...c.item })),
        );
      } else {
        reject(new Error("Fuse is not initialized"));
      }
    });

  const loadOptions = (inputValue: string) => searchOptions(inputValue);

  const debouncedLoadOptions = debounce(loadOptions, props.wait);

  useEffect(() => {
    setFuse(new Fuse(props.options, props.fuzzyOptions));
    return () => setFuse(null);
  }, [props.options, props.fuzzyOptions]);

  useEffect(() => {
    if ((props.options, fuse)) {
      fuse.setCollection(props.options);
    }
  }, [fuse, props.options]);

  const styles = {
    control: (base: CSSObject) => ({
      ...base,
      fontSize: "1.5rem",
      borderColor: "#D1D1D1",
    }),
    menu: (base: CSSObject) => ({
      ...base,
      fontSize: "1.5rem",
    }),
  };

  return (
    <Select
      value={activeOption}
      styles={styles}
      maxMenuHeight={190}
      isMulti={false}
      menuPlacement={"top"}
      className={"font-face-primary"}
      defaultOptions={props.options}
      onChange={props.onChange}
      // @ts-expect-error "(value: string) => Promise<unknown> is not assignable to type"
      loadOptions={(value: string) => debouncedLoadOptions(value)}
    />
  );
};

export default FuzzyReactSelect;
