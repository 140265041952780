export const damageLocations = [
  {
    label: "Voorruit",
    value: 1,
    img: "frontWindow",
  },
  {
    label: "Zijruit",
    value: 2,
    img: "sideWindow",
  },
  {
    label: "Achterruit",
    value: 3,
    img: "backWindow",
  },
  {
    label: "Dakraam",
    value: 4,
    img: "roofWindow",
  },
];

export const damageSizes = [
  {
    label: "Kleiner dan een munt van 2,-",
    value: 5,
  },
  {
    label: "Groter dan een munt van 2,-",
    value: 6,
  },
  {
    label: "Grote barst of ernstige beschadiging",
    value: 7,
  },
];

export const damageExactSizes = [
  {
    label: "Kleiner dan 1cm",
    value: 8,
  },
  {
    label: "Groter dan 1cm",
    value: 9,
  },
];

export const damageExactLocations = [
  {
    label: "De schade zit aan de bijrijderskant",
    value: 11,
  },
  {
    label: "De schade zit aan de bestuurderskant",
    value: 10,
  },
];

export const damageCauses = [
  {
    label: "Steenslag",
    value: 12,
  },
  {
    label: "Inbraak",
    value: 13,
  },
  {
    label: "Aanrijding",
    value: 14,
  },
  {
    label: "Vandalisme",
    value: 15,
  },
  {
    label: "Storm",
    value: 16,
  },
];
