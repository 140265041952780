import { ApiEnum } from "../../constants/ApiEnum";
import axios from "axios";
import { disableApiError } from "../../../app/rootReducer";
import { Dispatch } from "redux";

export const ApiDossierDelete = async (
  dossierUuid: string,
  fundingType: string,
  dispatch: Dispatch,
) => {
  return await axios
    .delete(ApiEnum.DOSSIER + "/delete", {
      data: {
        uuid: dossierUuid,
        funding_type: fundingType,
      },
    })
    .then(function (response) {
      dispatch(disableApiError());
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};
