export const ApiEnum = {
  POSTCODE: process.env.REACT_APP_BASE_URL + "/api/v1/postcode",
  GEOCODE: process.env.REACT_APP_BASE_URL + "/api/v1/geocode",
  POSTCODE_AUTO_COMPLETE:
    process.env.REACT_APP_BASE_URL + "/api/v1/postcode/autocomplete",
  DOSSIER: process.env.REACT_APP_BASE_URL + "/api/v1/dossier",
  REGISTRATION_LINK:
    process.env.REACT_APP_BASE_URL + "/api/v1/registration-link",
  EMAIL_ESTABLISHMENT:
    process.env.REACT_APP_BASE_URL +
    "/api/v1/dossier/unknown-contractor/send-mail",
  CARAVAN_SEND_MAIL:
    process.env.REACT_APP_BASE_URL + "/api/v1/dossier/camper/send-mail",
};
