interface SelectProps {
  label?: string;
  optional?: boolean;
  onChange: (value: string) => void;
  defaultValue?: string;
  value?: number;
  options?: number[];
}

const FormSelectNumber = (props: SelectProps) => {
  return (
    <div className="c-form__group">
      <label className={"font-face-primary"} htmlFor="">
        {props.label} {props.optional && <small>(optioneel)</small>}
      </label>

      <div className="c-form__select">
        <select
          className={"font-face-primary color-primary"}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        >
          <option>{props.defaultValue}</option>
          {props.options &&
            props.options.reverse().map((mappedOption: number) => {
              return (
                <option value={mappedOption} key={"buildYear-" + mappedOption}>
                  {mappedOption}
                </option>
              );
            })}
        </select>

        <i className="icon-circle-button-down color-secondary-before" />
      </div>
    </div>
  );
};

export default FormSelectNumber;
