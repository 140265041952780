import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { format } from "date-fns";
import { nl } from "date-fns/locale";
import { CapacityInterface } from "../../../common/interfaces/AgendaMomentInterface";
import { setTime } from "../appointmentSlice";
import { useEffect, useRef } from "react";
import { updateDossier } from "../../../app/rootReducer";
import { PatchStepsEnum } from "../../../common/constants/PatchStepsEnum";
import { setGeneralStep } from "../../helpType/helpTypeSlice";
import { GeneralStepsEnum } from "../../../common/constants/GeneralStepsEnum";
import { dossierTypeEnum } from "../../../types/dossierTypeEnum";

export const AppointmentTimePicker = () => {
  const { dossier } = useAppSelector((state) => state.root);
  const { agendaMoments, selectedDate, selectedTime } = useAppSelector(
    (state) => state.appointment,
  );
  const { activeHelpType } = useAppSelector((state) => state.helpType);
  const { activeAgendaMoment } = agendaMoments;

  const dispatch = useAppDispatch();

  const initialMountTime = useRef(true);
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      block: "start",
      inline: "nearest",
      behavior: "smooth",
    });

    if (!initialMountTime.current) {
      if (
        agendaMoments.activeAgendaMoment &&
        selectedDate &&
        selectedTime &&
        selectedTime.startTime &&
        selectedTime.startTime !== "" &&
        selectedTime.endTime &&
        selectedTime.endTime !== ""
      ) {
        dispatch(setGeneralStep(GeneralStepsEnum.Appointment));
        dispatch(
          updateDossier({
            data: {
              establishment_id:
                agendaMoments.activeAgendaMoment.establishment.id,
              date: selectedDate,
              start_time: selectedTime.startTime,
              end_time: selectedTime.endTime,
            },
            method: PatchStepsEnum.PatchAppointment,
          }),
        ).then(() => {
          if (
            activeHelpType === dossierTypeEnum.GlassReplacement ||
            activeHelpType === dossierTypeEnum.GlassRepair
          ) {
            dispatch(setGeneralStep(GeneralStepsEnum.Form));
          }
        });
      }
    } else {
      initialMountTime.current = false;
    }
  }, [selectedTime]);

  if (
    dossier &&
    selectedDate &&
    activeAgendaMoment &&
    activeAgendaMoment.availability &&
    activeAgendaMoment.availability?.dates
  ) {
    const date = activeAgendaMoment.availability?.dates.find(
      (availability: { date: string }) => availability.date === selectedDate,
    );

    if (date && date.capacities) {
      return (
        <div
          className="s-appointment__group border-color-primary"
          ref={scrollRef}
        >
          <h1 className="s-appointment__group__title s-appointment__group__title--no-margin">
            {format(new Date(selectedDate), "EEEE d MMMM yyyy", { locale: nl })}
          </h1>
          <p>Hoe laat wil je jouw {dossier.car_brand} brengen?</p>

          <div className="c-form__row">
            <div className="c-form__buttons c-form__buttons--row">
              {Object.values(date.capacities).map(
                (capacity: CapacityInterface) => {
                  return (
                    <Time {...capacity} key={"times-" + capacity.start_time} />
                  );
                },
              )}
            </div>
          </div>
        </div>
      );
    } else return null;
  } else return null;
};

const Time = (props: CapacityInterface) => {
  const dispatch = useAppDispatch();
  const { selectedTime } = useAppSelector((state) => state.appointment);

  let className =
    "c-form__buttons__single border-color-primary border-color-primary-hover";

  if (
    selectedTime &&
    props.start_time === selectedTime.startTime &&
    props.end_time === selectedTime.endTime
  ) {
    className += " button-active";
  }

  return (
    <button
      onClick={() =>
        dispatch(
          setTime({
            startTime: props.start_time,
            endTime: props.end_time,
          }),
        )
      }
      className={className}
    >
      <span className={"font-face-primary "}>
        {props.start_time} - {props.end_time}
      </span>
    </button>
  );
};

export default AppointmentTimePicker;
