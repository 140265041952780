import {
  caravanSendExplanationEmail,
  deleteDossier,
  deleteDossierId,
  setFinished,
  toggleBovagTerms,
  togglePrivacy,
  toggleTerms,
  updateDossier,
} from "../../../app/rootReducer";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { PatchStepsEnum } from "../../../common/constants/PatchStepsEnum";
import { setGeneralStep } from "../../helpType/helpTypeSlice";
import { GeneralStepsEnum } from "../../../common/constants/GeneralStepsEnum";
import { dossierTypeEnum } from "../../../types/dossierTypeEnum";
import { useParams } from "react-router-dom";
import { fundingTypeEnum } from "../../../types/fundingTypeEnum";

export const SubmitButton = () => {
  const termsAcceptedText =
    "Je geeft hierbij opdracht aan ABS Autoherstel om de reparatie in werking te zetten. Hiermee ga je akkoord dat ABS Autoherstel jouw gegevens deelt met de vestiging van jouw keuze. De vestiging zal contact met je opnemen om de afspraak te maken. Als je niet wilt dat wij jouw gegevens doorgeven kun je via deze weg geen afspraak maken. Je kunt natuurlijk altijd zelf contact opnemen met de vestiging.";
  const privacyAcceptedText = "Ik ga akkoord met de privacyverklaring.";
  const bovagAcceptedText =
    "Ik ga akkoord met de algemene voorwaarden volgens BOVAG.";
  const dispatch = useAppDispatch();
  const { root } = useAppSelector((state) => state);
  const { activeHelpType } = useAppSelector((state) => state.helpType);
  const isCaravanDamage = activeHelpType === dossierTypeEnum.CaravanRepair;
  const { caravanDamage } = useAppSelector((state) => state.vehicleInformation);
  const { slug } = useParams();
  const localDossierUuidKey = slug + "-dossier_uuid";
  const localDossierAccessTokenKey = slug + "-dossier_access_token";

  const {
    dossier,
    termsAccepted,
    registrationLink,
    privacyAccepted,
    bovagAccepted,
  } = useAppSelector((state) => state.root);

  const agendaMoments = useAppSelector(
    (state) => state.appointment.agendaMoments,
  );

  const submit = () => {
    if (isCaravanDamage && root && root.dossier) {
      const dossierUuid = root.dossier.dossier_uuid
        ? Number(root.dossier.dossier_uuid)
        : 0;
      const dossierAccessToken = root.dossier.dossier_access_token || "";
      const explanationCaravanDamage = caravanDamage.explanation || "";
      const establishmentId =
        agendaMoments.activeAgendaMoment?.establishment.id || 0;

      dispatch(
        caravanSendExplanationEmail({
          dossierUuid,
          dossierAccessToken,
          explanationCaravanDamage,
          establishmentId,
        }),
      ).then(() => {
        dispatch(
          deleteDossier({
            dossierUuid: dossierUuid.toString(),
            fundingType: fundingTypeEnum.Unknown,
          }),
        );
        dispatch(setFinished(true));
        dispatch(setGeneralStep(GeneralStepsEnum.Finished));
        dispatch(deleteDossierId());
        localStorage.removeItem(localDossierUuidKey);
        localStorage.removeItem(localDossierAccessTokenKey);
      });
    }

    if (!isCaravanDamage) {
      dispatch(
        updateDossier({
          data: {
            confirm_appointment: 1,
            terms_accepted: termsAccepted,
            privacy_accepted: privacyAccepted,
            bovag_accepted: bovagAccepted,
            terms_accepted_text: termsAcceptedText,
            privacy_accepted_text: privacyAcceptedText,
            bovag_accepted_text: bovagAcceptedText,
          },
          method: PatchStepsEnum.PatchConfirmAppointment,
        }),
      ).then(() => {
        dispatch(setFinished(true));
        dispatch(setGeneralStep(GeneralStepsEnum.Finished));
      });
    }
  };

  if (dossier) {
    return (
      <div className="s-appointment__group border-color-primary">
        <div className="c-form__row">
          <div className="c-form__group c-form__group--checkbox">
            <label>
              <input
                type="checkbox"
                value={termsAccepted}
                checked={!!termsAccepted}
                onChange={() => dispatch(toggleTerms())}
              />
              <span style={{ fontSize: "1.3rem" }}>{termsAcceptedText}</span>
            </label>
          </div>
          <div className="c-form__group c-form__group--checkbox">
            <label>
              <input
                type="checkbox"
                value={bovagAccepted}
                checked={!!bovagAccepted}
                onChange={() => dispatch(toggleBovagTerms())}
              />
              <span style={{ fontSize: "1.3rem" }}>
                Ik ga akkoord met de{" "}
                <a
                  href={registrationLink.terms_and_condition_url}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  algemene voorwaarden
                </a>{" "}
                volgens BOVAG.
              </span>
            </label>
          </div>
          <div className="c-form__group c-form__group--checkbox">
            <label>
              <input
                type="checkbox"
                value={privacyAccepted}
                checked={!!privacyAccepted}
                onChange={() => dispatch(togglePrivacy())}
              />
              <span style={{ fontSize: "1.3rem" }}>
                Ik ga akkoord met de{" "}
                <a
                  href={registrationLink.privacy_agreement_url}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  privacyverklaring
                </a>
                .
              </span>
            </label>
          </div>
        </div>
        <div className="c-form__buttons">
          <button
            disabled={
              !root.termsAccepted ||
              !root.bovagAccepted ||
              !root.privacyAccepted
            }
            onClick={() => submit()}
            className="c-form__buttons__single c-form__buttons__single___primary background-primary"
          >
            <span className="font-face-primary">Schademelding bevestigen</span>
          </button>
        </div>
      </div>
    );
  } else return null;
};
