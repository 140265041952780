export const SvgBiggerThan = () => {
  return (
    <svg
      width="35px"
      height="32px"
      viewBox="0 0 35 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Group 26</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-26">
          <g
            id="Group-8"
            transform="translate(16.113636, 26.847861) rotate(-90.000000) translate(-16.113636, -26.847861) translate(11.500000, 12.347861)"
            className={"fill-secondary"}
          >
            <polygon
              id="Fill-6"
              points="3.09490707 28.3409091 5.73127071 28.3409091 5.73127071 0.659090909 3.09490707 0.659090909"
            ></polygon>
            <polygon
              id="Fill-2"
              points="0.00181455942 2.92152985 8.35097278 2.92152985 8.35097278 0.470023037 0.00181455942 0.470023037"
            ></polygon>
            <polygon
              id="Fill-3"
              points="0 15.6329176 8.35097278 15.6329176 8.35097278 13.1818182 0 13.1818182"
            ></polygon>
            <polygon
              id="Fill-4"
              points="0 28.8147729 8.34915822 28.8147729 8.34915822 26.3636364 0 26.3636364"
            ></polygon>
          </g>
          <polygon
            className="fill-hover fill-primary"
            id="Fill-1"
            points="30.1038598 6.62642313 29.9228257 6.78639571 26.4808721 9.82677827 20.5006448 7.75860096 20.2998195 7.68924708 19.9383612 7.75526531 16.772805 8.33386294 17.8259345 5.20001248 18.0949441 4.39987157 17.489215 4.1266951 15.0503033 3.02592804 17.5662105 9.59232693e-14 13.9493227 2.85386369 13.4847068 3.22050807 14.0415004 3.63114144 16.0139662 5.08611366 14.204099 8.28042292 6.86465821 3.4599805 6.5620309 3.2611614 6.32630016 3.35650562 -4.97379915e-14 5.92141796 6.31545573 4.61147736 12.4968472 10.4764671 10.1264587 13.4496501 9.70318713 13.9804366 9.90516459 14.3151838 11.650643 17.2087974 11.5069543 14.4875956 14.2210434 12.4929417 14.8296869 13.7828684 15.0756521 14.3040649 15.3645205 14.4266503 21.0906494 16.8579279 21.5164965 20.847861 21.9773847 16.530408 22.0012424 16.3049731 21.737994 16.116161 16.9418425 12.6746656 16.6226775 11.2935643 20.2860607 10.0757017 26.4581666 11.2804301 26.8819804 11.3631961 27.1059178 11.1025979 30.2679496 7.42503519 34.7312449 8.82010455 30.2790651 6.7095366"
          ></polygon>
        </g>
      </g>
    </svg>
  );
};
