export const howToItems = [
  {
    codeName: "privateLease",
    content:
      "<p>Privé een auto leasen noem je ook wel private lease. Je betaalt een bedrag per maand incl. verzekering, wegenbelasting en onderhoud. De polisvoorwaarden van private lease contracten kunnen afwijken. Check vooraf de details met betrekking tot vervangend vervoer, eigen risico etc. Of neem contact op met je leasemaatschappij.</p>",
    title: "Private Lease",
  },
  {
    codeName: "businessLease",
    content:
      "<p>Heb je een auto van de zaak? Dan noemen we dat zakelijke lease. Jouw werkgever betaalt een bedrag per maand incl. verzekering, onderhoud, wegenbelasting etc. Je hebt bij autoschade vaak geen eigen risico en hebt recht op vervangend vervoer. Let op: het vervangend vervoer is niet altijd dezelfde klasse als jouw eigen auto. Dat is dan in de voorwaarden zo bepaald.</p>",
    title: "Zakelijke Lease",
  },
  {
    codeName: "borrowVehicles",
    content:
      "<p>Als maatschappelijk verantwoorde onderneming, vinden wij het belangrijk om bij te dragen aan een\n" +
      "gezonde, veilige en toekomstbestendige samenleving voor ons allemaal. Dit betekent dat wij veel\n" +
      "aandacht voor duurzaamheid hebben in al onze activiteiten, waaronder de inzet van vervangende\n" +
      "auto’s. We bieden graag duurzame alternatieven van vervangend vervoer. Doe je mee?</p>",
    title: "Leenvoertuigen",
  },
  {
    codeName: "borrowCar",
    content:
      "<p>Hoe lang je een leenauto krijgt kan afhankelijk zijn van de duur van de reparatie en drukte bij de vestiging. Een eventuele eigen bijdrage is afhankelijk van jouw verzekeringspolis of afspraken met jouw leasemaatschappij.</p>",
    title: "Leenauto",
  },
  {
    codeName: "pickup",
    content:
      "<p>Haal- en brengservice is gratis binnen een straal van 30 kilometer.</p>",
    title: "Haal- en brengservice",
  },

  {
    codeName: "borrowBicycle",
    content:
      "<p>Indien beschikbaar. Jouw vestiging neemt contact met je op.</p>",
    title: "Leenfiets",
  },

  {
    codeName: "locationSelect",
    content:
      "<p>Je kunt hier de postcode of plaatsnaam invullen die je het beste uitkomt. Dit hoeft niet jouw woonadres te zijn. <br /> Na invoer worden de dichtstbijzijnde vestigingen geselecteerd.</p>",
    title: "Postcode of plaatsnaam",
  },
];
