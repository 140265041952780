import { useState } from "react";
import { setEmail } from "../form/FormSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { updateDossier } from "../../app/rootReducer";
import { PatchStepsEnum } from "../../common/constants/PatchStepsEnum";
import DossierInterface from "../../common/interfaces/DossierInterface";

export const EmailNotification = () => {
  const dispatch = useAppDispatch();

  const { dossier } = useAppSelector((state) => state.root);
  const { person } = useAppSelector((state) => state.form);

  const [inputClass, setInputClass] = useState(
    dossier?.person?.email ? "confirmed" : "",
  );
  const [checked, setChecked] = useState(!!dossier?.person?.email);

  function handleSubmit() {
    setInputClass("confirmed");
    setChecked(true);
    dispatch(
      updateDossier({
        data: { email: person.email },
        method: PatchStepsEnum.PatchEmail,
      }),
    ).then((result) => {
      if (
        result.payload &&
        (result.payload as DossierInterface).person?.email
      ) {
        setInputClass("confirmed");
        setChecked(true);
      }
    });
  }

  return (
    <form
      className="s-appointment__alert background-light"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <p className="s-appointment__alert__title font-face-primary">
        <b>Blijf op de hoogte van je schademelding</b>
      </p>
      <p className="s-appointment__alert__text">
        Vul gemakkelijk je e-mailadres in:
      </p>

      <div className="c-form__row c-form__row--label">
        <label>
          <b>E-mailadres</b>
        </label>
      </div>

      <div className="c-form__row s-appointment__alert--email-check">
        <div className="c-form__group s-appointment__alert--email-check__input">
          <div className="c-input__group">
            <input
              className={inputClass}
              type="email"
              value={person.email}
              onChange={(e) => {
                setChecked(false);
                setInputClass("");
                dispatch(setEmail(e.target.value));
              }}
              placeholder="voorbeeld@emailadres.nl"
            />
            {checked && <img src="./images/groen-check.svg" alt="check" />}
          </div>
        </div>
        <div className="c-form__buttons">
          <button
            type={"submit"}
            className="c-form__buttons__single c-form__buttons__single___primary background-primary"
          >
            <span className="font-face-primary">E-mail opslaan</span>
          </button>
        </div>
      </div>

      <p className="small">
        Meer informatie over hoe wij verantwoord met je gegevens omgaan kun je
        via{" "}
        <a
          href="https://www.absautoherstel.nl/over-abs/privacy-en-cookieverklaring/"
          rel="noreferrer"
          target="_blank"
        >
          deze link
        </a>{" "}
        vinden.
      </p>
    </form>
  );
};
