import {
  setDamageCause,
  setDamageDate,
  setFundingType,
  setGlassDamageCause,
  setGlassDamageExactLocation,
  setGlassDamageExactSize,
  setGlassDamageLocation,
  setGlassDamageSize,
  setGreenCardCode,
  setInsuranceActiveContractor,
  setLeaseActiveContractor,
  setLeaseType,
  setLicense,
} from "../../features/vehicleInformation/vehicleInformationSlice";
import {
  setGeneralStep,
  setHelpType,
} from "../../features/helpType/helpTypeSlice";
import { GeneralStepsEnum } from "../constants/GeneralStepsEnum";
import {
  setActiveAgendaMoment,
  setCoords,
  setDate,
  setLoanType,
  setTime,
  setUserLocationInput,
} from "../../features/appointment/appointmentSlice";
import { AgendaMomentInterface } from "../interfaces/AgendaMomentInterface";
import { format } from "date-fns";
import {
  setAddressCity,
  setAddressHouseNumber,
  setAddressHouseNumberAddition,
  setAddressStreet,
  setAddressZipcode,
  setEmail,
  setFirstName,
  setInsertion,
  setLastName,
  setMobile,
} from "../../features/form/FormSlice";
import type { AppDispatch } from "../../app/store";
import { dossierTypeEnum } from "../../types/dossierTypeEnum";
import { fundingTypeEnum } from "../../types/fundingTypeEnum";
import { ApiGetCoordsByAddress } from "../api/GeoServices/getCoordsByAddress";

import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  TypedLazyQueryTrigger,
} from "@reduxjs/toolkit/query/react";

export type LazyGetTriggerType<T, U> = TypedLazyQueryTrigger<
  U, // Response type
  T, // Request parameters type
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>
>;

export interface DossierResult {
  dossier_id: number;
  dossier_access_token: string;
  car_license_plate?: string;
  appointment_type?: {
    code: string;
  };
  glass_damage?: {
    glass_damage_location?: number;
    glass_damage_size?: number;
    glass_damage_exact_location?: number;
    glass_damage_exact_size?: number;
    glass_damage_cause?: number;
  };
  damage_cause?: {
    id: number;
  };
  contractor?: {
    id: number;
  };
  lease_car?: number;
  lease_type?: {
    code: string;
  };
  appointment?: {
    establishment?: {
      id: number;
      address?: {
        city?: string;
      };
    };
    date_start?: string;
    start_time: string;
    end_time: string;
  };
  person?: {
    first_name?: string;
    insertion?: string;
    last_name?: string;
    email?: string;
    address?: {
      zip_code?: string;
      house_number?: number;
      house_number_addition?: string;
      street?: string;
      city?: string;
    };
    mobile_number?: string;
  };
  green_card_code?: string;
  damage_date?: string;
  loan_vehicle?: {
    code: string;
  };
}

export const fillFormByDossier = async (
  dossierResult: DossierResult,
  dispatch: AppDispatch,
  agendaMoments: {
    count?: number;
    limit: number;
    activeAgendaMoment?: AgendaMomentInterface;
    fast: number;
    loading: boolean;
  },
  agendaMomentTrigger: any,
) => {
  const {
    dossier_id,
    dossier_access_token,
    car_license_plate,
    appointment_type,
    glass_damage,
    damage_cause,
    contractor,
    lease_car,
    lease_type,
    appointment,
    person,
    green_card_code,
    damage_date,
    loan_vehicle,
  } = dossierResult;

  if (car_license_plate) dispatch(setLicense(car_license_plate));
  dispatch(setGeneralStep(GeneralStepsEnum.TypeSelect));

  if (appointment_type?.code) {
    dispatch(setHelpType(appointment_type.code));
  }

  if (
    appointment_type?.code ===
    (dossierTypeEnum.BodyRepair || dossierTypeEnum.CaravanRepair)
  ) {
    dispatch(setGeneralStep(GeneralStepsEnum.DamageCause));

    if (damage_cause?.id) {
      dispatch(setDamageCause(Number(damage_cause.id)));
      dispatch(setGeneralStep(GeneralStepsEnum.Lease));
    }
  }

  if (
    appointment_type?.code === dossierTypeEnum.GlassRepair ||
    appointment_type?.code === dossierTypeEnum.GlassReplacement
  ) {
    dispatch(setGeneralStep(GeneralStepsEnum.GlassDamage));

    if (glass_damage?.glass_damage_location)
      dispatch(
        setGlassDamageLocation(Number(glass_damage.glass_damage_location)),
      );
    if (glass_damage?.glass_damage_size)
      dispatch(setGlassDamageSize(Number(glass_damage.glass_damage_size)));
    if (glass_damage?.glass_damage_exact_location)
      dispatch(
        setGlassDamageExactLocation(
          Number(glass_damage.glass_damage_exact_location),
        ),
      );
    if (glass_damage?.glass_damage_size)
      dispatch(
        setGlassDamageExactSize(Number(glass_damage.glass_damage_exact_size)),
      );
    if (glass_damage?.glass_damage_cause)
      dispatch(setGlassDamageCause(Number(glass_damage.glass_damage_cause)));
  }

  if (lease_car === 1) {
    dispatch(setFundingType(fundingTypeEnum.Lease));
    dispatch(setGeneralStep(GeneralStepsEnum.Lease));
    if (contractor?.id)
      dispatch(setLeaseActiveContractor(Number(contractor.id)));
    if (lease_type?.code) dispatch(setLeaseType(lease_type.code));
    dispatch(setGeneralStep(GeneralStepsEnum.Appointment));
  }

  if (lease_car === 0 && !!contractor?.id) {
    dispatch(setFundingType(fundingTypeEnum.Particulier));
    dispatch(setGeneralStep(GeneralStepsEnum.Insurance));
    if (contractor?.id)
      dispatch(setInsuranceActiveContractor(Number(contractor.id)));
    dispatch(setGeneralStep(GeneralStepsEnum.Appointment));
  }

  if (
    appointment?.establishment &&
    (lease_car === 1 || (lease_car === 0 && !!contractor?.id))
  ) {
    const { establishment } = appointment;
    if (establishment?.address?.city) {
      dispatch(setUserLocationInput(establishment.address.city));
      const getCoords = await ApiGetCoordsByAddress(establishment.address.city);
      const results = getCoords.data.status.message;

      if (results) {
        dispatch(setCoords({ lat: results.lat, lng: results.lng }));

        const fundingType =
          lease_car === 1 ? fundingTypeEnum.Lease : fundingTypeEnum.Particulier;

        agendaMomentTrigger({
          dossierId: dossier_id,
          dossierAccessToken: dossier_access_token,
          lat: results.lat,
          long: results.lng,
          include_distances: true,
          sort: agendaMoments.fast ? "date" : "distance",
          fundingType: fundingType,
        }).then((result: { data: { data: AgendaMomentInterface[] } }) => {
          const selectedAgendaMoment = result.data?.data.find(
            (agendaMoment: AgendaMomentInterface) =>
              agendaMoment.establishment.id === establishment.id,
          );
          if (selectedAgendaMoment) {
            dispatch(setActiveAgendaMoment(selectedAgendaMoment));

            if (appointment?.date_start) {
              dispatch(
                setDate(format(new Date(appointment.date_start), "yyyy-MM-dd")),
              );
              dispatch(
                setTime({
                  startTime: appointment.start_time,
                  endTime: appointment.end_time,
                }),
              );
            }

            if (loan_vehicle?.code) {
              dispatch(setLoanType(loan_vehicle.code));
              dispatch(setGeneralStep(GeneralStepsEnum.Form));
            }
          }
        });
      }
    }
  }

  if (person?.first_name) dispatch(setFirstName(person.first_name));
  if (person?.insertion) dispatch(setInsertion(person.insertion));
  if (person?.last_name) dispatch(setLastName(person.last_name));
  if (person?.email) dispatch(setEmail(person.email));
  if (person?.address?.zip_code)
    dispatch(setAddressZipcode(person.address.zip_code));
  if (person?.address?.house_number)
    dispatch(setAddressHouseNumber(person.address.house_number));
  if (person?.address?.house_number_addition)
    dispatch(
      setAddressHouseNumberAddition(person.address.house_number_addition),
    );
  if (person?.address?.street)
    dispatch(setAddressStreet(person.address.street));
  if (person?.address?.city) dispatch(setAddressCity(person.address.city));
  if (person?.mobile_number) dispatch(setMobile(person.mobile_number));

  if (green_card_code) dispatch(setGreenCardCode(green_card_code));

  if (damage_date) {
    dispatch(setDamageDate(format(new Date(damage_date), "yyyy-MM-dd")));
  }
};
