import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  setGeneralStep,
  setGlassReplacement,
  setHelpType,
} from "./helpTypeSlice";
import { useEffect, useRef } from "react";
import { SvgDamageIcon } from "../../common/components/svgIcons/SvgDamageIcon";
import { SvgGlassIcon } from "../../common/components/svgIcons/SvgGlassIcon";
import { SvgCamper } from "../../common/components/svgIcons/SvgCamper";
import { GeneralStepsEnum } from "../../common/constants/GeneralStepsEnum";
import { updateDossier } from "../../app/rootReducer";
import { PatchStepsEnum } from "../../common/constants/PatchStepsEnum";
import {
  setFundingType,
  unsetDamageCause,
  unsetGlassDamage,
} from "../vehicleInformation/vehicleInformationSlice";
import { unsetAppointment } from "../appointment/appointmentSlice";
import { dossierTypeEnum } from "../../types/dossierTypeEnum";
import { fundingTypeEnum } from "../../types/fundingTypeEnum";
import { useSearchParams } from "react-router-dom";

const helpTypes = [
  {
    label: "Autoschade",
    value: dossierTypeEnum.BodyRepair,
    image: "autoschade.svg",
    introduction: "Schade aan uw auto",
  },
  {
    label: "Ruitschade",
    value: dossierTypeEnum.GlassRepair,
    svg: "ruitschade.svg",
    introduction: "Een kras, sterretje of volledige ruitschade",
  },
  {
    label: "Camper- en Caravanschade",
    value: dossierTypeEnum.CaravanRepair,
    svg: "caravan-en-camperschade.svg",
    introduction: "Schade aan uw caravan",
  },
];

export const HelpTypeSelect = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const { activeHelpType } = useAppSelector((state) => state.helpType);
  const scrollRef = useRef<HTMLDivElement>(null);
  const getParamHelpType = searchParams.get("help-type");

  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      block: "start",
      inline: "nearest",
      behavior: "smooth",
    });

    if (getParamHelpType) {
      onClick({ value: getParamHelpType });
    }

    window.dataLayer.push({
      event: "AanmeldenLinkType",
    });
  }, []);

  const onClick = (helpType: { value: string }) => {
    dispatch(setHelpType(helpType.value));
    dispatch(setGeneralStep(GeneralStepsEnum.TypeSelect));
    dispatch(unsetDamageCause());
    dispatch(unsetGlassDamage());
    dispatch(setFundingType(fundingTypeEnum.Unknown));
    dispatch(setGlassReplacement(false));
    dispatch(unsetAppointment());

    dispatch(
      updateDossier({
        data: { appointment_type: helpType.value },
        method: PatchStepsEnum.PatchAppointmentType,
      }),
    ).then(() => {
      if (
        helpType.value === dossierTypeEnum.BodyRepair ||
        helpType.value === dossierTypeEnum.CaravanRepair
      ) {
        dispatch(setGeneralStep(GeneralStepsEnum.DamageCause));
      }
      if (
        helpType.value === dossierTypeEnum.GlassRepair ||
        helpType.value === dossierTypeEnum.GlassReplacement
      ) {
        dispatch(setGeneralStep(GeneralStepsEnum.GlassDamage));
      }
      if (helpType.value === dossierTypeEnum.CaravanRepair) {
        dispatch(setGeneralStep(GeneralStepsEnum.Appointment));
      }
    });
  };

  return (
    <div className="s-appointment__group border-color-primary" ref={scrollRef}>
      <h1 className="s-appointment__group__title">Soort schade</h1>
      <div className="c-icon-button c-icon-button--row c-icon-button--horizontal">
        {helpTypes.map((helpType) => {
          let activeClass = "";
          if (activeHelpType === helpType.value) {
            activeClass = " button-active";
          }
          return (
            <button
              key={"help-type-" + helpType.value}
              onClick={() => onClick(helpType)}
              className={
                "c-icon-button__single border-color-primary border-color-primary-hover" +
                activeClass
              }
            >
              {helpType.value === dossierTypeEnum.BodyRepair && (
                <SvgDamageIcon />
              )}
              {(helpType.value === dossierTypeEnum.GlassRepair ||
                helpType.value === dossierTypeEnum.GlassReplacement) && (
                <SvgGlassIcon />
              )}
              {helpType.value === dossierTypeEnum.CaravanRepair && (
                <SvgCamper />
              )}

              <span className="font-face-primary">{helpType.label}</span>
            </button>
          );
        })}
      </div>
    </div>
  );
};
