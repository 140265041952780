export const SvgGlassIcon = () => {
  return (
    <svg
      width="42.13px"
      height="32.35px"
      viewBox="0 0 57 44"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <polygon
          id="path-20m_aieda2-1"
          points="0 0.00879119967 56.1711195 0.00879119967 56.1711195 43.1375799 0 43.1375799"
        ></polygon>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="211118-ABS-platform-[autoschade-1]"
          transform="translate(-882.000000, -417.000000)"
        >
          <g id="Group-15" transform="translate(882.000000, 417.000000)">
            <path
              className="fill-secondary"
              d="M12.6,5.382312 L43.2,5.382312 C51.1911009,13.182312 52.6366513,18.382312 47.5366513,20.982312 C39.8866513,24.882312 17.211776,24.8122716 8.63447079,20.982312 C2.91626731,18.4290056 4.23811038,13.2290056 12.6,5.382312 Z"
              id="Rectangle"
              fill=""
            ></path>
            <g id="Group-3">
              <mask id="mask-20m_aieda2-2" fill="white">
                <use href="#path-20m_aieda2-1"></use>
              </mask>
              <g id="Clip-2"></g>
              <path
                className="fill-primary"
                d="M47.5367569,32.5865827 C45.3297698,32.5865827 43.4541819,30.7103084 43.4541819,28.504034 C43.4541819,26.2970733 45.2334098,24.4216702 47.5367569,24.4216702 C49.8398928,24.4216702 51.6191208,26.2970733 51.6191208,28.504034 C51.6191208,30.7103084 49.8398928,32.5865827 47.5367569,32.5865827 L47.5367569,32.5865827 Z M8.63440479,32.5865827 C6.33124248,32.5865827 4.55201455,30.7103084 4.55201455,28.504034 C4.55201455,26.2970733 6.33124248,24.4216702 8.63440479,24.4216702 C10.9377255,24.4216702 12.716927,26.2970733 12.716927,28.504034 C12.716927,30.7103084 10.8413391,32.5865827 8.63440479,32.5865827 L8.63440479,32.5865827 Z M12.951227,8.3528356 C14.082731,6.06382369 15.0342397,5.42935251 17.4337621,5.42935251 L28.0818321,5.42935251 L38.7373469,5.42935251 C41.1368956,5.42935251 42.0884043,6.06382369 43.2198819,8.3528356 L48.7503121,20.1592848 L28.1647809,20.1592848 L28.0063809,20.1592848 L7.42084964,20.1592848 L12.951227,8.3528356 Z M55.1367094,20.6973695 L46.9986457,3.66361498 C46.061129,1.67764866 43.4541819,0.00877271967 41.2472476,0.00877271967 L28.0818321,0.00877271967 L14.9238878,0.00877271967 C12.716927,0.00877271967 10.1100063,1.67764866 9.17246317,3.66361498 L1.03442588,20.6973695 C0.207419512,22.3662455 -5.2799998e-06,23.6073886 -5.2799998e-06,25.1799046 L-5.2799998e-06,41.8964367 C-5.2799998e-06,42.6136455 0.510491501,43.1375799 1.24185067,43.1375799 L7.93063362,43.1375799 C8.64784239,43.1375799 9.17246317,42.6271095 9.17246317,41.8964367 L9.17246317,37.7035625 L28.0063809,37.7035625 L28.1647809,37.7035625 L46.9986457,37.7035625 L46.9986457,41.8964367 C46.9986457,42.6271095 47.5232929,43.1375799 48.2405017,43.1375799 L54.929311,43.1375799 C55.6606438,43.1375799 56.1711406,42.6136455 56.1711406,41.8964367 L56.1711406,25.1799046 C56.1711406,23.6073886 55.9637158,22.3662455 55.1367094,20.6973695 L55.1367094,20.6973695 Z"
                id="Fill-1"
                fill=""
                mask="url(#mask-20m_aieda2-2)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
