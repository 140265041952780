export const SvgVandalisme = () => {
  return (
    <svg
      className="damage-vandalism"
      width="43px"
      height="43px"
      viewBox="0 0 43 43"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="211118-ABS-platform-[ruitschade-2]"
          transform="translate(-441.000000, -1524.000000)"
        >
          <g
            id="103279_baseball_icon"
            transform="translate(441.000000, 1524.000000)"
          >
            <path
              d="M5.350001,0.350004 C2.219127,0.350004 0.350001,2.2335 0.350001,5.350004 C0.350001,7.850004 5.350001,11.599992 7.850001,14.099976 C12.849873,19.100016 21.104673,23.60478 29.100003,31.60002 L36.600003,39.10002 L35.350001,40.350004 C35.350001,41.59998 36.600003,42.85002 37.850001,42.85002 L40.350001,40.350004 L42.850001,37.84998 C42.850001,36.6 41.600001,35.35002 40.350001,35.35002 L39.099999,36.6 L31.599999,29.1 C24.441627,21.94176 19.099875,12.850004 14.100003,7.850004 C11.600001,5.350004 7.850001,0.350004 5.350001,0.350004 L5.350001,0.350004 Z"
              id="path20806"
              fill="#E67A01"
              className="fill-secondary"
              fillRule="nonzero"
            ></path>
            <path
              d="M14.100003,25.35 C12.028923,25.35 10.350003,27.02898 10.350003,29.1 C10.350003,31.17102 12.028923,32.85 14.100003,32.85 C16.171077,32.85 17.850003,31.17102 17.850003,29.1 C17.850003,27.02898 16.171077,25.35 14.100003,25.35 Z"
              id="path20806-path"
              fill="#E67A01"
              className="fill-primary"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
