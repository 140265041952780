import { ApiEnum } from "../../constants/ApiEnum";
import axios from "axios";
import { disableApiError } from "../../../app/rootReducer";
import { Dispatch } from "redux";

export const ApiDossierGet = async (
  dossierUuid: string,
  dossierAccessToken: string,
  dispatch: Dispatch,
) => {
  return await axios
    .post(ApiEnum.DOSSIER + "/get", {
      dossierUuid: dossierUuid,
      dossierAccessToken: dossierAccessToken,
    })
    .then(function (response) {
      dispatch(disableApiError());
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};
