import { enableHelpModal } from "../../../app/rootReducer";
import { useAppDispatch } from "../../../app/hooks";
import ModalWrapper from "./ModalWrapper";

const SmartPhotoModal = () => {
  const dispatch = useAppDispatch();

  return (
    <ModalWrapper>
      <h3>Binnen 5 minuten jouw schade in kaart gebracht!</h3>
      <p>
        Via de slimme software van onze IT partner Solera kun je gemakkelijk
        foto’s maken en deze aan ons opsturen. Op basis van deze foto’s kunnen
        wij een inschatting maken van de reparatietijd en de te bestellen
        materialen.
      </p>
      <ul>
        <li>Je ontvangt een link naar onze slimme software via sms</li>
        <li>Maak eenvoudig de juiste foto’s van de schade</li>
        <li>Stuur deze direct naar onze vestiging</li>
      </ul>
      <p>
        <strong>*Let op!</strong> Op basis van de foto’s maken wij een
        inschatting van de reparatieduur. Het is geen definitieve schadeanalyse.
        Onze vestiging maakt de uiteindelijke analyse tijdens de afspraak.
      </p>
      <div className="c-modal__buttons">
        <a
          onClick={() => dispatch(enableHelpModal())}
          className="btn btn--gray"
        >
          <span>Help mij verder</span>
        </a>
      </div>
    </ModalWrapper>
  );
};

export default SmartPhotoModal;
