export const PatchStepsEnum = {
  PatchModel: "patchModel",
  patchCarLicensePlate: "patchCarLicensePlate",
  PatchYear: "patchYear",
  PatchDamageCause: "patchDamageCause",
  PatchFundingType: "patchFundingType",
  PatchContractor: "patchContractor",
  PatchLeaseCar: "patchLeaseCar",
  PatchLeaseTypeCode: "patchLeaseTypeCode",
  PatchGreenCardCode: "patchGreenCardCode",
  PatchLoanVehicle: "patchLoanVehicle",
  PatchDamageDate: "patchDamageDate",
  PatchPerson: "patchPerson",
  PatchEmail: "patchEmail",
  PatchAppointment: "patchAppointment",
  PatchAppointmentType: "patchAppointmentType",
  PatchConfirmAppointment: "patchConfirmAppointment",
  PatchGlassDamageLocation: "patchGlassDamageLocation",
  PatchGlassDamageExactSize: "patchGlassDamageExactSize",
  PatchGlassDamageExactLocation: "patchGlassDamageExactLocation",
  PatchGlassDamageCause: "patchGlassDamageCause",
  PatchGlassDamageSize: "patchGlassDamageSize",
};
